import React, { Component } from "react";

export default class Ads extends Component {
  render() {
    return (
      <div style={{ textAlign: "center", paddingBottom: 20 }}>
        <a href="https://one.exness-track.com/a/c_0buwzjm5sb" target="_blank" rel="sponsored noopener" title="TH-GIF banner">
        <img src="https://d3dpet1g0ty5ed.cloudfront.net/TH_0821_METALS_HUMAN_Want_better_gold_tra_728x90px.jpg" width="728" height="90" alt="" />
        </a>
      </div>
    );
  }
}
